import React from "react"
import { asCurrency } from "../../services/Utils"
import Hammer from 'react-hammerjs';
import { AddToCart } from "./AddToCart"
import { ProductUnitPrice } from "./ProductUnitPrice"

export class ProductGallery extends React.Component {
  constructor(props) {
    super(props)

    this.handleSwipe = this.handleSwipe.bind(this)
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyPress.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress.bind(this))
  }

  handleKeyPress(event) {
    const key = event.keyCode

    if (!this.props.visible) return

    if (key == 39)
      // right
      this.next()
    else if (key == 37)
      // left
      this.previous()
    else if (key == 27)
      // esc
      this.props.closeGallery()
  }

  handleSwipe(event) {
    if (event.direction == 2) { this.next() }
    else if ( event.direction == 4) { this.previous() }
  }

  next() {
    this.props.changeGalleryIndex(this.props.index + 1)
  }

  previous() {
    this.props.changeGalleryIndex(this.props.index - 1 )
  }

  render() {
    if (!this.props.visible || !this.props.product_unit) { return (<div />) }

    return (
      <div id="modal-mask" onClick={ this.props.closeGallery }>
        <div className="modal-popout" onClick={ (event) => { event.stopPropagation() } }>
          <div>
            <div className="text-center px-5 py-1 cursor-pointer" onClick={ this.props.closeGallery }>
              <i className="fa fa-lg fa-times" aria-hidden="true" />
            </div>
          </div>
          {this.renderDisplay()}
        </div>
      </div>
    )
  }

  renderIndividualUnitPrice(product_unit) {
    if ( !this.props.showPricing || !product_unit.individual_unit_name) { return }
    if ( product_unit.individual_unit_price == product_unit.price ) { return }
    return (
      <div className='mt-2'>
        { asCurrency(product_unit.individual_unit_price) } / { product_unit.individual_unit_name }
      </div>
    )
  }

  renderProductUnits(other_product_units) {
    const otherUnits = other_product_units.map( (product_unit) => {
      if ( product_unit.id == this.props.product_unit.id ) { return }
       return (
        <div className='py-2 border-top' key={ product_unit.product_unit_id }>
          <div className='product-unit'>{ product_unit.unit }</div>
          <div><ProductUnitPrice product_unit={ product_unit } showPricing={ this.props.showPricing }/></div>
          <div className='row'>
            <div className="col-lg-4">
              <AddToCart
                product_name={ product_unit.name }
                product_unit={ product_unit }
                show_unit={ true }
                src='gallery_other_units'
                colorClass='border-dark'
                seller_id={ product_unit.seller_id }
                showPricing={ this.props.showPricing }
                cartEnabled={ this.props.cartEnabled }
                addItem={ this.props.addItem }/>
            </div>
          </div>
        </div>
      )
    })
    if (otherUnits.filter(Boolean).length) {
      return (
        <div className='border-top mt-3'>
          <div className='mt-3 h5 font-weight-bold'>OTHER UNITS</div>
          { otherUnits }
        </div>
      )
    }
  }

  renderDisplay() {
    const product_unit = this.props.product_unit
    const rightarrow = (
      <div className="col-md-2 py-5 col-1 align-self-center text-right cursor-pointer" onClick={this.next.bind(this)}>
        <i className="fa fa-2x fa-chevron-right" aria-hidden="true" />
      </div>
    )

    const leftarrow = (
      <div className="col-md-2 py-5 col-1 align-self-center text-left cursor-pointer" onClick={this.previous.bind(this)}>
        <i className="fa fa-2x fa-chevron-left" aria-hidden="true" />
      </div>
    )

    let city_state_tag
    if (product_unit.city && product_unit.state) {
      city_state_tag = (
        <div className="producer-city-state">
          {product_unit.city}, {product_unit.state}
        </div>
      )
    }

    var image = (product_unit.product_photo || {}).large_url
    return (
      <div id="product-overlay">
        <div className='row no-gutters'>
          { leftarrow }
          <div className='col-md-8 col-10 mb-2 text-center'>
            <Hammer onSwipe={ this.handleSwipe }>
              <img className='img-fluid image-placeholder' src={image} />
            </Hammer>
          </div>
          { rightarrow }
        </div>
        <div className="row no-gutters justify-content-center">
          <div className='col-md-8 col-10 mb-4'>
            <div className='producer-details mb-2'>
              <div className="product-producer">
                {product_unit.producer_name}
              </div>
              <div>{ city_state_tag }</div>
            </div>
            <div className="product-name">
              { product_unit.name }
            </div>
            <div className='product-unit'>
              { product_unit.unit }
            </div>
            <div className='h5 mt-2'>
                <ProductUnitPrice product_unit={ product_unit } showPricing={ this.props.showPricing } />
              </div>
            { this.renderIndividualUnitPrice(product_unit) }
            <div className="product-description">
              { product_unit.description}
            </div>
            <div className='row'>
              <div className="col-lg-4">
                <AddToCart
                  product_unit={ product_unit }
                  seller_id={ product_unit.seller_id }
                  showPricing={ this.props.showPricing }
                  cartEnabled={ this.props.cartEnabled }
                  addItem={ this.props.addItem }
                  src='catalog_gallery'
                />
              </div>
            </div>
            <div className="product-prices grid">
              { this.renderProductUnits(product_unit.otherProducts || []) }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
